<template>
  <content-card-split :background="true" :collapsed="collapsed">
    <template #title>
      <card-title @toggleTopCard="toggleTopCard" :topcard="true" :expanded="!collapsed">
        <template #title><h2>Investments</h2></template>
        <template #content>
        </template>
      </card-title>
    </template>
    <template #content-left>
      <div class="content-container">
        <div class="content-title">
          <h3>TRANSFER A PRODUCT</h3>
        </div>
        <div class="content flex-grow-1">
          <div class="content-body">
            <h5 class="title">Do you have a holding outside clarity?</h5>
            <div class="blurb">Do you want to benefit from automatic daily valuations? If so, contact your adviser to find out more.</div>
          </div>
          <div class="content-actions">
            <Button class="clarity-btn clarity-gradient-orange full-width" @click="$router.push('/securemessaging/message'); newMessageGlobal();">Contact your Adviser</Button>
          </div>
        </div>
      </div>
    </template>
    <template #content-right>
      <div class="content-container">
        <div class="content-title">
          <h3>BANK ACCOUNT OR CREDIT CARD</h3>
        </div>
        <div class="content flex-grow-1">
          <div class="content-body">
            <h5 class="title">Add your bank account or credit card</h5>
            <div class="blurb">Do you want your bank account or credit card values added to your valuation on your dashboard in an automatic daily feed? If so, add them now through open banking.</div>
          </div>
          <div class="content-actions">
            <Button class="clarity-btn clarity-gradient-orange full-width" @click="$router.push('/investments/openbanking')">Add Account / Card</Button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:bottom>
      <div class="card-bottom">
        <div class="bottom-actions">
          <div class="btn-group">
            <Button class="clarity-btn clarity-gradient-blue" @click="$router.push('/ims/imshome')">IMS Home</Button>
            <Button class="clarity-btn clarity-gradient-blue" @click="$router.push('/investments/datafeeds')">Fund Provider Data Feed</Button>
          </div>
          <div class="btn-group">
            <Button class="clarity-btn clarity-gradient-blue" @click="$router.push('/investments/portfolios')">Managing your Portfolios</Button>
            <Button class="clarity-btn clarity-gradient-blue" @click="$router.push('/investments/permissions')">Managing your Permissions</Button>
          </div>
        </div>
      </div>
    </template>
  </content-card-split>
</template>
<script>
import {useStore} from "vuex";
import {inject, onBeforeUnmount, ref} from 'vue'
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardSplit from '@/components/common/ContentCardSplit.vue'

export default {
  name: 'InvestmentTopCard',
  components: {CardTitle, ContentCardSplit},
  setup() {
    const store = useStore()
    const bus = inject('bus')
    const collapsed = ref(true)

    bus.on('small', () => {
      console.log('small detected')
    })
    bus.on('tablet', () => {
      console.log('tablet detected')
    })

    const toggleTopCard = () => {
      collapsed.value = !collapsed.value
    }

    const adviser = { name: store.getters.getAdviserName };

    const newMessageGlobal = () => {
      store.dispatch('globalMessageTrigger', {
        subject: 'Transfer a Product',
        category: 'Correspondence',
        recipient: adviser.name,
        messagebody: 'Please replace this with a message detailing the products you wish to transfer.'
      })
    }

    onBeforeUnmount(() => {
        bus.off('small')
        bus.off('tablet')
    });

    return {
      collapsed,
      toggleTopCard,
      newMessageGlobal
    }
  }
}
</script>
<style scoped lang="scss">
.content-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mob-sm {
    flex-direction: column;
  }
}

.content-body {
  padding-top: 20px;
  padding-bottom: 20px;

  .blurb {
    display: flex;
    @include mob {
      display: none;
    }
  }
}

.content-actions {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;

  button {
    min-width: 220px;
  }
}

button {
  justify-content: center;
  margin: 5px;
}

.card-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.bottom-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 20px;

  @include mob {
    flex-direction: column;
    column-gap: 20px;
  }

  .btn-group {
    display: flex;
    flex-direction: row;
    width: 50%;
    column-gap: 20px;

    @include mob-sm {
      flex-direction: column;
      width: 100%;
    }

    button {
      width: 50%;
      padding: 0 45px;
      margin: 15px 0px;
      font-size: 18px;

      @include mob-sm {
        padding: 0 30px;
        margin: 15px 0px;
        font-size: 16px;
        width: 100%;
      }
    }
  }
}


</style>
