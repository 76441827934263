<template>
  <div class="flex">
    <Dropdown v-model="selectedHeader" :options="headers" optionLabel="label" class="full-width">
      <template #option="slotProps">
        <div>{{slotProps.option.label}}</div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import {computed, watch, ref, onMounted,} from 'vue'
import {useRouter} from "vue-router";

export default {
  name: "TabViewDropdown",
  props: {
    headers: {
      type: Array,
      required: true
    },
    rootPage: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const router = useRouter()
    const selectedHeader = ref()
    const headersModel = ref(props.headers)

    onMounted(() => {
      selectedHeader.value = activeHeader.value
    })

    const activeHeader = computed(() => {
      return props.headers.find(header => header.active === true)
    })

    watch(() => props.headers, (val) => {
      headersModel.value = val
    })

    watch(selectedHeader, val => {
      headersModel.value = headersModel.value.map((item) => {
        item.active = val.label === item.label
        return item
      })
      // @TODO: update this if there are subsequent pages that have sub nested tabs
      if (!['/tools'].includes((props.rootPage))) {
        context.emit('change', headersModel.value)
        router.push(props.rootPage + '/' + activeHeader.value.to)
      }
    })

    return {
      activeHeader,
      selectedHeader
    }
  }
}
</script>

<style scoped>

</style>
