<template>
    <div class="p-5 flex flex-column">

        <InvestmentTopCard />
        <spacer-h/>
        <div class="tab-container">
            <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/ims'"/>
            <div v-if="mobile">
                <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/ims'"/>
                <spacer-h/>
            </div>

            <router-view></router-view>

            <HelpCard mode="investments" class="pt-5" />
        </div>
    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
    import {useStore} from 'vuex'
    import {useRoute} from "vue-router";
    import SpacerH from '@/components/common/layout/SpacerH.vue';
    import TabViewHeaders from "@/components/nav/TabViewHeaders";
    import TabViewDropdown from "@/components/nav/TabViewDropdown";
    import InvestmentTopCard from "@/components/top_cards/InvestmentTopCard";
    import HelpCard from "@/components/common/HelpCard";


export default {
        setup() {
            const route = useRoute();
            const store = useStore();
            const mobile = ref(false)
            const screenSize = computed(() => store.getters.getTabView)

            const headers = computed(() => store.getters.getTabHeaders('ims'));

            const onTabHeadersChange = (payload) => {
                //console.log(payload);
                store.dispatch('setActiveHeader', {page: 'ims', headers: payload})
            }

            const isRootPage = computed(() => {
                return route.path === '/ims/imshome'
            })

            watch(screenSize, (val) => {
                mobile.value = val === 'mobile'
            })

            onMounted(() => {
                mobile.value = screenSize.value === 'mobile'
            })

            return {
                headers,
                onTabHeadersChange,
                isRootPage,
                mobile
            }
        },
        components: {
            SpacerH,
            TabViewHeaders,
            TabViewDropdown,
            InvestmentTopCard,
            HelpCard
        }
    }
</script>
<style scoped>

</style>
