<template>
    <content-card-full>
        <template #title>
            <card-title>
                <template #title>
                    <h2 class="title">IMS HOME</h2>
                </template>
            </card-title>
        </template>
        <template #content-full>
            <!-- Show relevent template here depending on client status -->
            <div v-if="isUpgraded">
                <i-m-s-approval />
            </div>
            <div v-if="!isUpgraded">
                <i-m-s-upgrade />
            </div>
        </template>
    </content-card-full>

    <spacer-h/>

    <content-card-full>
        <template #title>
            <card-title>
                <template #title>
                    <h2 class="title">IMS FAQs AND INFO</h2>
                </template>
            </card-title>
        </template>
        <template #content-full>
            <div class="grid">
                <div class="col-8">
                    <div class="flex flex-column justify-content-between">
                        <p class="faq-title">FAQs</p>
                        <h5 class="blue">Can I approve some, not all, of the recommended switches?</h5>
                        <p>The switches are grouped together within the individual accounts, so although you may select between products (eg, process the trades within your ISA, but not your pension), the trades within the products that are selected for processing will be transacted in their entirety. Also please do consider that the rebalance proposals are made with the overall portfolio in mind, and so rebalancing some and not all of the products may mean that your portfolio asset allocation does not match the proposed final position.</p>
                        <h5 class="blue">How long are these recommendations valid for?</h5>
                        <p>IMS rebalance recommendations will remain live for approval on this page for up to two months after they are issued. Please do note, however, we do not generally advise returning these instructions more than one month after they are issued as the advice can date, particularly if we have estimated CGT liabilities.</p>
                        <h5 class="blue">Can I discuss the recommendations with my adviser, prior to approval?</h5>
                        <p>Of course. Please contact us on <b>0800 368 7511</b> or via <a><router-link to="/securemessaging/inbox">Secure Message</router-link></a> to arrange a call with your clarity adviser.</p>
                        <router-link to="/ims/imsfaqs"><Button class="clarity-btn clarity-gradient-grey my-3 read-more-btn">Read more</Button></router-link>
                    </div>
                </div>
                <div class="col-4">
                    <div class="flex flex-column meet-the-team">
                        <img src="https://ditijyr6nby2p.cloudfront.net/newsales/claritymadeassets/img/team/meetimsteamgroup.png" alt="Meet the team">
                        <h3 style="text-align:center;">MEET THE TEAM</h3>
                        <a :href="clarityURL+'/aboutteam'" target="_blank"><Button class="clarity-btn clarity-gradient-orange my-3 see-team-btn">See full Team</Button></a>
                    </div>
                </div>
            </div>
        </template>
    </content-card-full>
</template>

<script>
    import CardTitle from '@/components/common/CardTitle';
    import ContentCardFull from '@/components/common/ContentCardFull';
    import SpacerH from '@/components/common/layout/SpacerH.vue';
    import IMSUpgrade from '../ims/IMSUpgrade.vue';
    import IMSApproval from '../ims/IMSApproval.vue';

    import {ref} from 'vue';

    export default {
        name: 'IMSHome',
        setup() {
            const isUpgraded = ref(true);
            const clarityURL = process.env.VUE_APP_CLARITYURL;

            return {
                isUpgraded,
                clarityURL
            }
        },
        components: {
            CardTitle,
            ContentCardFull,
            SpacerH,
            IMSUpgrade,
            IMSApproval
        }
    }
</script>

<style scoped>
    p {
      font-size: var(--clarity-font-size-18);
    }
    .see-team-btn {
        width: 100%;
        justify-content: center;
        margin: 5px;
    }
    .read-more-btn {
        width: 182px;
        justify-content: center;
        margin: 5px;
    }
    .faq-title {
        font-size: 22px;
        color: var(--clarity-dark-grey);
    }
    .meet-the-team {
        text-align: center;
    }
    a:-webkit-any-link, a:-webkit-any-link:active {
      color: var(--clarity-light-blue);
    }
</style>
