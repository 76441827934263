<template>
  <div class="p-tabview p-component app-headers">
    <div class="p-tabview-nav-container" data-cy="tab-container">
      <div class="p-tabview-nav-content">
        <ul class="p-tabview-nav" role="tablist">

          <li v-for="header in headersModel" :key="header.label" role="presentation" :data-cy="`tab-header-${$case(header.label, 'kebab')}`"
              :class="[{ 'p-highlight' : isActiveHeader(header) },{'disabled' : header.disabled }]"
          >
            <a @click="headerSelected(header)" class="p-tabview-nav-link" tabindex="0" aria-selected role="tab">
              <h3>{{ header.label }}</h3>
            </a>
          </li>
          <li class="p-tabview-ink-bar" :style="{'width': '19vw', 'left': getActiveTabPosition + 'px'}"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {ref,watch, computed} from "vue";
import {useRouter} from "vue-router";

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    rootPage: {
      type: String,
      required: true
    }
  },
  name: "TabViewHeaders",
  emits:['change'],
  setup(props, context) {
    const router = useRouter()
    const headersModel = ref(props.headers)

    watch(() => props.headers, (val) => {
      headersModel.value = val
    })

    const headerSelected = (header) => {
      headersModel.value = headersModel.value.map((item) => {
        item.active = header.label === item.label
        return item
      })

      const selected = headersModel.value.find(el => el.active === true)
      context.emit('change', headersModel.value)
      router.push(props.rootPage+'/'+selected.to)
    }

    const isActiveHeader = (header) => {
      return header.active
    }

    const getActiveTabPosition = computed(() => {
      const idx = headersModel.value.findIndex(tab => tab.active === true)
      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const offset = (19 * w) / 100;
      // if tab is unset but tabs are in view, first will be underlined but not active
      return idx > -1 ? idx * offset : 0
    })

    return {
      headersModel,
      headerSelected,
      isActiveHeader,
      getActiveTabPosition
    }
  }
}
</script>

<style scoped>
.p-tabview :deep(.p-tabview-nav) {
  background: unset;
  border: none;
}
.p-tabview :deep(.p-tabview-nav-container) {
  margin: 0px 30px;
}
.p-tabview :deep(.p-tabview-panels) {
  padding: unset;
  background: var(--clarity-snow-grey);
}
.p-tabview :deep(.p-tabview-nav li .p-tabview-nav-link) {
  background: var(--clarity-snow-grey);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  height: 75px;
  width: 19vw;
  color: var(--clarity-dark-grey);
}
.p-tabview :deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  background: var(--clarity-pure-white);
  color: var(--clarity-blue);
}
.p-tabview :deep(.p-tabview-ink-bar) {
  background-color: var(--clarity-blue);
  height: 5px;
  border-radius: 4px 4px 0px 0px;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding-left: 2px;
    padding-right: 2px;
}
</style>
