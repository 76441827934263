<template>
    <div class="application-box">
        <!-- Hidden Details -->
        <div v-if="!isExpanded">
            <div class="grid">
                <div class="col-8">
                    <h3 style="margin-top:25px;">{{ theDocument.policy }}</h3>
                </div>
                <div class="col-4">
                    <div class="flex justify-content-end">
                        <div @click.stop="toggleView" style="cursor: pointer;">
                            <div class="flex align-items-center grey"><span class="material-icons-round grey">expand_more</span>Show Details</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 xl:col-8">
                    <p>Approval by: <b>{{ clientName }}</b> | <span v-html="showStatusMessage"></span></p>
                </div>
                <div class="col-12 xl:col-4">
                    <div class="flex justify-content-end">
                        <Button class="clarity-btn clarity-gradient-grey my-3 justify-content-center" style="width: 306px;" @click="downloadDocs"><span class="material-icons">get_app</span>Download Form</Button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Show Details -->
        <div v-if="isExpanded">
            <div class="grid">
                <div class="col-8">
                    <h3>{{ theDocument.policy }}</h3>
                </div>
                <div class="col-4">
                    <div class="flex justify-content-end">
                        <div @click.stop="toggleView" style="cursor: pointer;">
                            <div class="flex align-items-center grey"><span class="material-icons-round grey">expand_less</span>Hide Details</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-12">
                    <p>Approval by: <b>{{ clientName }}</b> | <span v-html="showStatusMessage"></span></p>
                    <div class="application-detail">
                        <div class="grid">
                            <div class="col-6">
                                <h4 class="blue">SELL</h4>
                                <DataTable :value="sellFunds" stripedRows>
                                    <Column field="name"></Column>
                                    <Column field="value"></Column>
                                    <template #empty>
                                        NO FUND DATA
                                    </template>
                                </DataTable>
                            </div>
                            <div class="col-6">
                                <h4 class="blue">BUY</h4>
                                <DataTable :value="buyFunds" stripedRows>
                                    <Column field="name"></Column>
                                    <Column field="purchasevalue"></Column>
                                    <template #empty>
                                        NO FUND DATA
                                    </template>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-9">
                    <p class="body-text">You can share the Application details by downloading and filling the form.</p>
                </div>
                <div class="col-3">
                    <div class="flex justify-content-end">
                        <Button class="clarity-btn clarity-gradient-grey my-3 justify-content-center" @click="downloadDocs" style="width: 306px;"><span class="material-icons">get_app</span>Download Form</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from "dayjs";
    import {buildIMSApplicationPDFString} from "../../core/services/documentExport/imsApplicationPdfBuilder";
    import {computed, ref} from 'vue';
    import {useStore} from "vuex";
    import {downloadDocument} from "../../core/services/documentDownload";


    export default {
        name: 'IMSOfflineApproval',
        props: ['expanded', 'document'],
        setup(props) {
            const store = useStore();
            const isExpanded = ref(props.expanded);
            const theDocument = ref(props.document);

            // Values will be replaced by API Data
            const sellFunds = computed(() => {
                const transactons = JSON.parse(theDocument.value.transactions);
                return transactons['sales'];
            });

            const buyFunds = computed(() => {
                const transactons = JSON.parse(theDocument.value.transactions);
                return transactons['purchases'];
            });

            const clientName = computed(() => {
                const name = store.getters.personFromUuid(theDocument.value.approvalBy[0]);
                return name.name;
            });

            const toggleView = () => {
                isExpanded.value = !isExpanded.value
            }

            const showStatusMessage = computed(() => {
              // show approval status based on document stage.
              if(theDocument.value.stage == '0') {
                return '<span class="orange"><span class="material-icons-outlined" style="vertical-align:top;padding-right:10px;">warning</span><span class="josefin">APPROVAL STATUS: AWAITING APPROVAL (ACTION REQUIRED)</span></span>';
              } else {
                return '<span class="green"><span class="material-icons" style="vertical-align:top;padding-right:10px;">check_circle</span><span class="josefin">APPROVAL STATUS: MARKED FOR APPROVAL</span></span>';
              }
            });

            const downloadDocs = async () => {

                const payload = theDocument.value;
                const reference = payload.policy+'_Application_'+dayjs().format('DDMMYYYY');
                const applicationPdf = await buildIMSApplicationPDFString(payload, reference);

                if (applicationPdf) {

                    const fileName = `${reference}.pdf`
                    downloadDocument('pdf', applicationPdf, fileName, 'imsapplication_pdf')

                }
            }

            return {
                isExpanded,
                toggleView,
                sellFunds,
                buyFunds,
                clientName,
                theDocument,
                downloadDocs,
                showStatusMessage
            }
        }
    }
</script>

<style scoped>
    p {
        font-size: var(--clarity-font-size-18);
    }
    .material-icons-outlined, .material-icons {
        vertical-align: top;
        padding-right: 10px;
    }
    .application-box, .application-detail {
        background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--clarity-light-grey);
        border-radius: 27px;
        opacity: 1;
        padding: 30px;
        margin: 40px 0px;
    }
    .share-msg {
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        color: var(--clarity-dark-grey);
    }
    .josefin {
      font-family: var(--clarity-font-family-josefin);
    }
</style>
