<template>
    <div v-if="!services.investment && rebalanceList.length === 0">
        <div class="grid">
            <div class="col-12">
                <h3>UPGRADE YOUR SERVICE TO IMS</h3>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 xl:col-8">
                <p class="ims-selection-box-titles">Investment management services: a proven approach to wealth management</p>
                <p>Our investment services range from bespoke portfolio management with regular reviews and rebalancing, through to a non-advised transaction and administration service. When using our ongoing investment management services (IMS), your clarity adviser will recommend an appropriate investment strategy, based on your personal circumstances and goals. We’re then on hand, as required, to ensure your investments remain aligned with your aspirations and, crucially, your attitude to risk. If you’d rather make your own investment decisions, we provide a wide range of tools and research to help you, as well as our fund buy list – which is kept under continual review by our investment committee.</p>
                <p>If you want to know more about our approach to investment management, including how we select the funds on our buy list, please contact your Adviser.</p>
            </div>
            <div class="col-12 xl:col-4">
                <div class="flex justify-content-end">
                    <Button class="clarity-btn clarity-gradient-grey my-3" @click="$router.push('/securemessaging/message'); newMessageGlobal();" >Contact your Adviser</Button>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="grid">
            <div class="col-12">
                <h3>IMS APPROVAL CENTRE</h3>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 xl:col-8">
                <p>Welcome to your IMS Approval Centre, from this page you can review and approve IMS applications.<br>Please ensure you have read the Investment Management review and accompanying disclosure documentation before approving transactions.</p>
            </div>
            <div class="col-12 xl:col-4">
                <div class="flex justify-content-end">
                    <Button class="clarity-btn clarity-gradient-grey my-3" @click="showImsPreferenceModal">Update your Preferences</Button>
                </div>
            </div>
            <ims-send-method-modal v-show="showModal === 'updateIMSSendMethod'" ></ims-send-method-modal>
        </div>
        <div class="grid">
            <div class="col-6">
                <p class="ims-selection-box-titles">Select IMS Applications to approve</p>
                <Dropdown class="full-width" v-model="selectedRebalance" :options="rebalanceList" optionLabel="description" optionValue="id" @change="resetApproval"></Dropdown>
            </div>
            <div class="col-6"></div>
        </div>
        <div class="grid">
            <div class="col-12 mt-6">
                <h3>APPROVAL REQUIRED</h3>
            </div>
        </div>

        <!-- Show this if no approvals pending -->
        <div v-if="onlineApprovalRecordsCount < 1" class="no-approvals">
            <h3>NO PENDING APPROVALS</h3>
            <p>There are no IMS applications to approve yet. Please return later.<br>Do you need any help? Please contact your Adviser.</p>
        </div>

        <!-- Show this is we have approvals pending -->
        <div v-if="onlineApprovalRecordsCount > 0" class="approvals">
        <div class="grid">
            <div class="col-12">
              <p>By checking the boxes below you are giving your approval to the fund switches being transacted as set out below. Please review the following Applications, mark the ones you want to approve and click the “<b>Submit Approval</b>” button to confirm. Otherwise you can also share the Application details by filling this Form and share with us via <router-link to="/securemessaging/inbox">Secure Message</router-link>.</p>
            </div>
        </div>

        <div class="actions-required" v-if="toBeApprovedCount">
            <div class="grid">
                <div class="col-12 xl:col-8">
                    <h3 class="orange"><span class="material-icons-outlined">warning</span>ACTIONS REQUIRED</h3>
                    <p><b>You have {{ toBeApprovedCount }} Applications not approved yet.</b> Do you want to mark all the Applications available for approval? If yes, please select the “Mark all Applications for approval” on side and then click on the “Submit Approval” button to confirm.</p>
                </div>
                <div class="col-12 xl:col-4">
                    <Button @click="markAllForApproval" class="full-width clarity-btn clarity-gradient-grey my-3">
                        <Checkbox inputId="isAllApproveSelected" name="isAllApproveSelected" v-model="isAllApproveSelected" :binary="true" :disabled="true" class="mr-3" />
                        Mark all Applications for approval ({{ toBeApprovedCount }})
                    </Button>
                    <submit-button @submitted="submitForApproval" :loading="loadingState" button-text="Submit Approval" loading-text="Submitting Approval..." :button-class="'full-width clarity-btn clarity-gradient-orange my-3 justify-content-center'" ></submit-button>
                </div>
            </div>
        </div>

        <div v-if="onlineApprovalRecords">
            <div v-for="document in onlineApprovalRecords" :key="document.id+document.stage">
                <i-m-s-online-approval :expanded="showDetails" :document="document" />
            </div>
        </div>

        <div class="actions-required" v-if="toBeApprovedCount">
            <div class="grid">
                <div class="col-12 xl:col-8">
                    <h3 class="orange"><span class="material-icons-outlined">warning</span>ACTIONS REQUIRED</h3>
                    <p><b>You have {{ toBeApprovedCount }} Applications not approved yet.</b> Do you want to mark all the Applications available for approval? If yes, please select the “Mark all Applications for approval” on side and then click on the “Submit Approval” button to confirm.</p>
                </div>
                <div class="col-12 xl:col-4">
                    <Button @click="markAllForApproval" class="full-width clarity-btn clarity-gradient-grey my-3">
                        <Checkbox inputId="isAllApproveSelected" name="isAllApproveSelected" v-model="isAllApproveSelected" :binary="true" :disabled="true" class="mr-3" />
                        Mark all Applications for approval ({{ toBeApprovedCount }})
                    </Button>
                    <submit-button @submitted="submitForApproval" :loading="loadingState" button-text="Submit Approval" loading-text="Submitting Approval..." :button-class="'full-width clarity-btn clarity-gradient-orange my-3 justify-content-center'" ></submit-button>
                </div>
            </div>
        </div>

        <ims-error-modal v-show="showModal === 'imsError'" ></ims-error-modal>


        <div class="grid" style="margin-top:60px;">
            <div class="col-12">
                <h3>OTHER APPROVAL REQUIRED</h3>
                <p>The following Applications are unavailable for online approval by "{{ clientName }}". Please log in using the account for "{{ partnerName }}" to approve these switches via clarity website. Alternatively, the Application forms can be downloaded to PDF and signed, then returned to us via email (<a href="mailto:IMS@clarityglobal.com">IMS@clarityglobal.com</a>) or <a><router-link to="/securemessaging/inbox">Secure Message</router-link></a>.</p>
            </div>
        </div>

        <div v-if="offlineApprovalRecords">
            <div v-for="document in offlineApprovalRecords" :key="document.id">
                <i-m-s-offline-approval :expanded="showDetails" :document="document" />
            </div>
        </div>

        </div>
        <div>
            <div class="col-12 mt-6">
                <h3>PREVIOUS APPROVALS</h3>
                <p>In this area, you can find all the IMS applications that have been approved recently.</p>
            </div>
            <DataTable
                :value="recentApprovals"
                class="rd-table"
                scrollable
                scrollHeight="1000px"
            >
                <Column header="Holding Name" sortable style="min-width: 60%;">
                    <template #body="slotProps">
                        <div>
                            <h3 class="light-blue mr-3 pt-2 mb-0" >{{ slotProps.data.policy }}</h3>
                            {{ slotProps.data.rebalance }}
                        </div>
                    </template>
                </Column>
                <Column header="Approval By">
                    <template #body="slotProps">
                        {{ approvalBy(slotProps.data.approvedBy) }}
                    </template>
                </Column>
                <Column header="Approval Date">
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.approvalDate) }}
                    </template>
                </Column>
                <template #footer>
                </template>
                <template #empty>
                    <div>No recent approvals found.</div>
                </template>
            </DataTable>
        </div>
    </div>


</template>

<script>
    import IMSOnlineApproval from './IMSOnlineApproval.vue';
    import IMSOfflineApproval from './IMSOfflineApproval.vue';
    import ImsSendMethodModal from '../modals/ImsSendMethodModal.vue';
    import ImsErrorModal from '../modals/ImsErrorModal.vue'

    import {computed, onMounted, ref, watch} from "vue";
    import {useStore} from "vuex";
    import SubmitButton from "../common/layout/interactive/SubmitButton";
    import dayjs from "dayjs";

    export default {
        name: 'IMSApproval',
        setup() {
            const store = useStore();
            const services = computed(() => store.getters.getClientServices);
            const showDetails = ref(false);
            const rawApprovalRecords = computed(() => {
                const records = store.getters.getApprovalRecords;
                return records;
            });
            const whoAmI = computed(() => {
                if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
                    if(store.getters.targetUUID){
                        return store.getters.targetUUID;
                    }
                }
                return store.getters.clarityUUID
            });
            const partnerUUID = ref();

            const recentApprovals = computed(() => {
                return store.getters.getRecentApprovals;
            })

            const loadingState = computed(() => store.getters.isLoading('IMSApproval'))

            const approvalRecords = computed(() => {
                return rawApprovalRecords.value;
            })

            const onlineApprovalRecords = computed(() => {
                let retArr = [];
                if(approvalRecords.value.length) {
                    retArr = [];
                    const approval = approvalRecords.value;
                    approval.forEach(element => {
                        if(element.adminRecordID === selectedRebalance.value) {
                            const docs = element.documents;
                            docs.forEach(elm => {
                                const approval = elm.approvalBy;
                                if(approval.includes(whoAmI.value)) {
                                    retArr.push(elm);
                                }
                            });
                        }
                    });
                    return retArr;
                } else {
                    return [];
                }
            });

            const toBeApproved = computed( () => {
              let retArr = [];
              onlineApprovalRecords.value.forEach((approval) => {
                if(approval.stage == '0'){
                  retArr.push(approval);
                }
              })
              return retArr
            })
            const toBeApprovedCount = computed(() => {
              return toBeApproved.value.length;
            });

            const onlineApprovalRecordsCount = computed(() => {
                return onlineApprovalRecords.value.length;
            });

            const offlineApprovalRecords = computed(() => {
                let retArr = [];
                if(approvalRecords.value.length) {
                    if( selectedRebalance.value === undefined) {
                        retArr = [];
                        const docs = approvalRecords.value[0].documents;
                        docs.forEach(element => {
                            const approval = element.approvalBy;
                            if(!approval.includes(whoAmI.value)) {
                                retArr.push(element);
                            }
                        });
                    } else {
                        retArr = [];
                        const approval = approvalRecords.value;
                        approval.forEach(element => {
                            if(element.adminRecordID === selectedRebalance.value) {
                                const docs = element.documents;
                                docs.forEach(elm => {
                                    const approval = elm.approvalBy;
                                    if(!approval.includes(whoAmI.value)) {
                                        partnerUUID.value = approval;
                                        retArr.push(elm);
                                    }
                                });
                            }
                        });
                    }
                    return retArr;
                } else {
                    return [];
                }
            });

            const currentSelectedApprovals = computed(() => {
                return store.getters.getApprovedRecords;
            })

            const submitForApproval = async () => {
                if(currentSelectedApprovals.value.length == 0){
                    store.dispatch('setActiveModal', {modal: 'imsError'})
                }else {
                    store.dispatch('setLoading', {id: 'IMSApproval', state: true})
                    let ids = [];
                    currentSelectedApprovals.value.forEach(element => {
                        ids.push(element)
                    });
                    store.dispatch('approveApplications', ids);
                }
            }

            const markAllForApproval = () => {
                if(isAllApproveSelected.value === false) {
                    const checkAll = [];
                    toBeApproved.value.forEach((approval) => {
                      approval.sibling ?
                      checkAll.push(approval.id, approval.sibling):
                      checkAll.push(approval.id);
                    })
                    store.dispatch('selectedForApproval',checkAll);
                } else {
                    // uncheck all
                   store.dispatch('selectedForApproval',[]);
                }
            }

            // reset the approval when select changes
            const resetApproval = () => {
              store.dispatch('selectedForApproval',[]);
            }
            const isAllApproveSelected = computed(() => {
              // set the state to a computed based on what is selected so it toggles on/off when granular changes are made to current selection
              let fail = 0;
              // check if all online approval documents are marked for approval.
              onlineApprovalRecords.value.forEach((approval) => {
                if(!currentSelectedApprovals.value.includes(approval.id)){
                  fail++
                }
              })
              if(fail === 0){
                return true;
              }else{
                return false;
              }
            })

            // on mount ensure the data feeds call is made!
            onMounted(() => {
              // dispatch the action to load the ims approval records.
              store.dispatch('getIMSApprovalRecords');
              store.dispatch('getPeopleOptions');
            })

            const rebalanceList = computed(() => {
                const rebalances = rawApprovalRecords;
                let arr = [];
                rebalances.value.forEach(element => {
                    arr.push({id:element.adminRecordID,description:element.description});
                });
                return arr;
            });

            const selectedRebalance = ref(false);

            watch(rebalanceList, (value) => {
              // ensure the rebalance selection is made when the list loads in
              if(!selectedRebalance.value && value.length > 0){
                selectedRebalance.value = value[0].id;
              }
            })

            const clientName = computed(() => {
                const name = store.getters.personFromUuid(whoAmI.value);
                return name.name;
            });

            const partnerName = computed(() => {
                const name = store.getters.personFromUuid(partnerUUID.value);
                return name.name;
            })

            const showImsPreferenceModal = () => {
                store.dispatch('setActiveModal', {modal: 'updateIMSSendMethod'})
            }

            const adviser = { name: store.getters.getAdviserName };

            const newMessageGlobal = () => {
                store.dispatch('globalMessageTrigger', {
                    subject: 'IMS Service',
                    category: 'IMS',
                    recipient: adviser.name,
                    messagebody: 'Please contact me to discuss the IMS service'
                })
            }

            const approvalBy = (uuid) => {
                const person = store.getters.personFromUuid(uuid);
                if(person && person.name){
                    return person.name;
                }else{
                    return 'Approved Manually'
                }
            }

            const formatDate = (date) => {
                return new dayjs(date).format('DD-MM-YYYY HH:mm:ss');
            }

            return {
                services,
                approvalRecords,
                onlineApprovalRecords,
                offlineApprovalRecords,
                onlineApprovalRecordsCount,
                submitForApproval,
                markAllForApproval,
                isAllApproveSelected,
                showDetails,
                selectedRebalance,
                rebalanceList,
                resetApproval,
                toBeApproved,
                toBeApprovedCount,
                loadingState,
                clientName,
                partnerName,
                showImsPreferenceModal,
                showModal: computed(() => store.getters.currentActiveModal),
                newMessageGlobal,
                recentApprovals,
                approvalBy,
                formatDate
            }
        },
        components: {
            SubmitButton,
            IMSOnlineApproval,
            IMSOfflineApproval,
            ImsSendMethodModal,
            ImsErrorModal
        }
    }
</script>

<style scoped>
    button {
        justify-content: center;
    }
    h3 {
        margin-bottom: 20px;
    }
    p {
      font-size: var(--clarity-font-size-18);
    }
    .material-icons-outlined {
        vertical-align: top;
        padding-right: 10px;
    }
    .actions-required {
        padding: 30px;
        background: transparent linear-gradient(180deg, #FFBA6233 0%, #F189034D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #F1890380;
        border-radius: 27px;
        opacity: 1;
    }
    .actions-required p {
        margin-left: 34px;
    }
    .actions-required h3 {
        margin-top: 41px;
    }
    .ims-selection-box-titles {
        font-size: 18px;
        font-weight: bold;
        color: var(--clarity-blue)
    }
    .no-approvals {
        background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--clarity-light-grey);
        border-radius: 27px;
        opacity: 1;
        padding: 30px;
        margin: 0px 0px 40px 0px;
        text-align: center;
    }
    .no-approvals h3 {
        text-align: center !important;
    }
    a:-webkit-any-link, a:-webkit-any-link:active {
      color: var(--clarity-light-blue);
    }
</style>
