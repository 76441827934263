<template>
  <div>
    <Button class="clarity-btn align-items-center justify-content-center" :class="buttonClass" :disabled="disableButton" @click="submitButton">
      <div class="flex justify-content-center">
        <div class="flex align-items-center justify-content-center" v-if="loading">
          <progress-spinner style="width:22px;height:22px;margin-right: 8px;" strokeWidth="8" animationDuration="2s"></progress-spinner>
        </div>
        <div class="flex align-items-center justify-content-center">
          <span>{{ computedButtonText }}</span>
          <span v-if="chevron" class="material-icons-round white ml-3">chevron_right</span>
        </div>
      </div>

    </Button>
  </div>
</template>
<script>
import {computed, toRef} from 'vue'

//<ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>

export default {
    props: {
        loading: Boolean,
        disabled: {
          default: false,
          type: Boolean,
        },
        buttonText: {
          default: "Submit",
          type: String
        },
        buttonClass: {
          default: "clarity-gradient-orange",
          type: String
        },
        loadingText: {
          default: "Saving....",
          type: String
        },
        chevron: {
            default: false,
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const isLoading = toRef(props, 'loading');
        const isDisabled = toRef(props, 'disabled');
        const computedButtonText = computed(() => {
          if(isLoading.value){
            return props.loadingText;
          }else{
            return props.buttonText;
          }
        })

        const disableButton = computed(() => {
          if(isLoading.value || isDisabled.value){
            return true;
          }
          return false;
        })

        const submitButton = () => {
          emit('submitted')
        }

        return {
          computedButtonText,
          disableButton,
          submitButton
        }
    },
}
</script>
<style scoped>
  .clarity-btn {
    width: 100%;
  }
</style>
