<template>
    <Modal :width="`858px`">
        <template #body>
            <content-card-full>
                <template #title>
                    <div class="flex justify-content-between title align-items-center">
                        <card-title>
                            <template #title>
                                <div class="flex" style="padding-top: 15px">
                                    <h3 class="blue">UPDATE YOUR PREFERENCES</h3>
                                </div>
                            </template>
                        </card-title>
                        <Button class="clarity-close-modal-btn" @click="closeModal">
                            <span class="material-icons-round grey">close</span>
                        </Button>
                    </div>
                </template>
                <template #content-full>
                    <div class="full-width flex flex-column">
                        <div>
                            <div v-if="currentSendMethod" class="flex-column">
                                <p class="body-text"><b>How to receive your IMS Alerts</b></p>
                                <div class="clarity-padding-bottom-2xs">
                                    <Checkbox  v-model="byEmail" :binary="true" /><label class="method-label" :class="{blue: byEmail}"><span :class="{boldtext: byEmail}">By Email</span> (on {{ clientEmail }}) </label>
                                </div>
                                <div class="clarity-padding-bottom-2xs">
                                    <Checkbox v-model="byPost" :binary="true" /><label class="method-label" :class="{selectedMethod: byPost}">By Post </label>
                                </div>
                                <div>
                                    <Checkbox v-model="bySecureMessage" :binary="true" /><label class="method-label" :class="{selectedMethod: bySecureMessage}">Secure Message </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-content-between btn-group clarity-margin-top-m">
                        <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
                        <submit-button @submitted="saveIMSmethod" :loading="loadingState" button-text="Save Changes" ></submit-button>
                    </div>
                </template>
            </content-card-full>
        </template>
    </Modal>
</template>

<script>
import {useStore} from "vuex";
import {ref, computed} from "vue";
import Modal from "@/components/common/Modal";
import ContentCardFull from '@/components/common/ContentCardFull';
import CardTitle from '@/components/common/CardTitle';
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
    name: 'ImsSendMethodModal',
    components: {
        Modal,
        ContentCardFull,
        CardTitle,
        SubmitButton
    },
    setup(){
        const store = useStore();
        const clientEmail = computed(() => store.getters.getEmail)
        const current = computed(() => store.getters.getImsSendMethod)
        const byEmail = ref(false);
        const byPost = ref(false);
        const bySecureMessage = ref(false);
        const noSelection = ref(true);

        const loadingState = computed(() => store.getters.isLoading('save-ims-send'))

        const closeModal = () => {
            store.dispatch('setActiveModal');
        }

        const currentSendMethod = computed(() => {
            if(current.value) {
                current.value.split(",").forEach(element => {
                    switch (element) {
                        case 'email':
                            byEmail.value = true;
                            break;
                        case 'securemessaging':
                            bySecureMessage.value = true;
                            break;
                        case 'post':
                            byPost.value = true;
                            break;
                        case 'pleaseselect':
                            noSelection.value = true;
                            break;
                        case 'both':
                            byPost.value = true;
                            byEmail.value = true;
                            break;
                        default:
                            noSelection.value = true;
                            break;
                    }
                });
            }
            return true;
        });

        const saveIMSmethod = async () => {
            await store.dispatch('setLoading', {id: 'save-ims-send', state: true})
            const methods = [];
            if(byEmail.value) {
                methods.push('email');
            }
            if(byPost.value) {
                methods.push('post');
            }
            if(bySecureMessage.value) {
                methods.push('securemessaging');
            }
            let payload = '';
            if(methods.length === 0) {
                payload += 'pleaseselect'
            } else {
                for(let x = 0; x < methods.length; x++) {
                    payload += methods[x];
                    if(x !== methods.length-1) {
                        payload += ',';
                    }
                }
            }

            await store.dispatch('setImsSendMethodOption',{
                type: 'settings',
                loading: 'settings-ims',
                title: 'IMS Send Method',
                payload: {
                    "method": payload
                }
            });
            await store.dispatch('setLoading', {id: 'save-ims-send', state: false})
            closeModal();
        }

        return {
            currentSendMethod,
            clientEmail,
            byEmail,
            byPost,
            bySecureMessage,
            noSelection,
            closeModal,
            saveIMSmethod,
            loadingState
        }
    }
}
</script>

<style scoped lang="scss">
.modal:deep(.modal-close) {
  display: none !important;
}

.modal:deep(.content-title) {
  padding: 10px 20px 10px 30px;
}

.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}
.modal:deep(.content-full) {
    padding-top: 0px !important;
}
.modal:deep(.filters) {
    padding: 0px;
}
.selectedMethod {
    color: #0058A9;
    font-weight: bold;
}
.boldtext {
    font-weight: bold;
}
.method-label {
    margin-left: 13px;
}
</style>
