<template>
  <div class="clarity-card" :class="[ isCollapsed ? 'collapsed' : '']">
    <div class="body" :class="[ hasBackground ? 'textured' : '']">
      <div class="content-title">
        <slot name="title"></slot>
      </div>
      <div class="content">
        <slot name="content-left" class="content-panel"></slot>
        <slot name="content-right" class="content-panel"></slot>
      </div>
    </div>
    <div class="bottom-slot">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>
<script>
import {computed, inject, onBeforeUnmount, ref} from "vue";

export default {
  props: ['background', 'collapsed'],
  setup(props) {
    const hasBackground = computed(() => {
      return props.background
    })

    const isCollapsed = computed(() => {
      return props.collapsed
    })

    const bus = inject('bus')
    const isMobile = ref(false)
    bus.on('mobile', () => {
      isMobile.value = true
    })
    bus.on('small', () => {
      isMobile.value = false
    })

    onBeforeUnmount(() => {
        bus.off('mobile')
        bus.off('small')
    });

    return {
      hasBackground,
      isCollapsed,
      isMobile
    }
  }
}
</script>
<style scoped lang="scss">
.clarity-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: max-content;
  border-radius: 27px;
  box-shadow: 0 5px 20px #0000000d;
  background: var(--clarity-light-grey);
  border: 2px solid var(--clarity-pure-white);

  @include mob {
    &.collapsed {
      height: 400px;
      overflow: hidden;

      .body {
        -webkit-mask-image: linear-gradient(var(--clarity-dark-grey) 0%, transparent);
      }
    }
  }
}

.body {
  border-radius: 27px;
  background: white;
}

.content {
  position: relative;
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px;
  height: -webkit-max-content;

  @include mob {
    display: flex;
    flex-direction: column;
  }
}

.content-panel {
  position: relative;
  display: flex;
  width: 100%;
}

.bottom-slot {
  display: flex;
  width: 100%;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
}

.content-title:deep(h2),
.content:deep(h5) {
  color: var(--clarity-blue);
}

.textured {
  background: url("../../assets/img/card-pattern-bg.png") center no-repeat;
  background-size: 105% 125%;
}
</style>
