
import store from '../../../store'

export async function buildIMSApplicationPDFString(payload, ref){
    // build the HTML string for the report download
    let htmlString = ''
    let headerString = await buildHeaderString()
    let footerString = await buildFooterString()
    let applicationString = await buildApplicationHtml(payload, ref)

    if(headerString && footerString && applicationString) {
        return htmlString+=headerString+=footerString+=applicationString
    }

    async function buildHeaderString() {
        return `<header id="header"></header>`
    }

    async function buildFooterString() {
        return  `<footer id="footer"></footer>`
    }

    async function buildApplicationHtml(payload, ref) {

        const transactionsParsed = JSON.parse(payload.transactions)

        let html = `<table style="width: 100%;"> <tr> <td colspan="2"><b>Ref: ${ref}</b></td></tr><tr> <td colspan="2"><br/></td></tr><tr>`

        html += `<td>Policy: <b>${payload.policy}</b></td><td style="text-align: right"></td></tr><tr> <td colspan="2"><br/></td></tr><tr> <td colspan="2">Dear Sir/Madam,</td></tr><tr> <td colspan="2"><br/></td></tr><tr> <td colspan="2">Please Accept this as my authority to sell the following funds:</td></tr></table><br/>`

        // next load the sell funds
        html += `<table class="ims-application" style="width: 100%;"> <tr> <td style="width: 50%"><b>Fund</b></td><td style="width: 25%"></td><td style="width: 25%; text-align: right;"><b>Sell % Of Existing Holding</b></td></tr>`
        if(transactionsParsed && transactionsParsed.sales) {
            transactionsParsed.sales.forEach((sale) => {
                html += `<tr><td>${sale.name}</td><td>${sale.isin}</td><td style="text-align: right">${sale.value}</td></tr>`
            })
        }

        html += `</table>`

        // spacer
        html += `<br/><p>Please reinvest the proceeds of the above sales into the following:</p>`

        // then the buy funds
        html += `<table class="ims-application" style="width: 100%;"> <tr> <td style="width: 50%"><b>Fund</b></td><td style="width: 25%"></td><td style="width: 25%; text-align: right;"><b>Invest % of Proceeds</b></td></tr>`

        if(transactionsParsed && transactionsParsed.purchases) {
            transactionsParsed.purchases.forEach((purchase) => {
                html += `<tr><td>${purchase.name}</td><td>${purchase.isin}</td><td style="text-align: right">${(Math.round(purchase.purchasevalue*100)/100)}</td></tr>`
            })
        }

        html += `</table>`



        // then the footer
        html += `<br/><p>If you require any further information, please contact the IMS team at clarity ltd on 0800 368 7511</p><p><b>Please maintain the existing income agreement</b></p><p>In signing this declaration I agree, with respect to clarity's involvement, to any charging adjustments, income unit changes and agree the asset allocation as stated in the covering letter</p><br/>`

        payload.approvalBy.forEach((uuid) => {
            const name = store.getters.personFromUuid(uuid).name;
            html += `<br /><br /><table className="ims-application"><tr><td style="width: 50%"> Signed: .................................................................<br/> ${name} </td><td style="width: 50%">Date: ...................................................................<br/>&nbsp;</td></tr></table>`
        })

        return html;

    }

}


