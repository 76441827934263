<template>
    <div class="grid">
        <div class="col-12">
            <h3>UPGRADE YOUR SERVICE TO IMS</h3>
            <h5 class="blue">Investment management services: a proven approach to wealth management</h5>
        </div>
    </div>
    <div class="grid">
        <div class="col-8">
            <p>Our investment services range from bespoke portfolio management with regular reviews and rebalancing, through to a non-advised transaction and administration service. When using our ongoing investment management services (IMS), your clarity adviser will recommend an appropriate investment strategy, based on your personal circumstances and goals. We’re then on hand, as required, to ensure your investments remain aligned with your aspirations and, crucially, your attitude to risk. If you’d rather make your own investment decisions, we provide a wide range of tools and research to help you, as well as our fund buy list – which is kept under continual review by our investment committee.</p>
            <p>If you’d like to subscribe to the investment management services (IMS), please click on the “Upgrade your Account” button on side. If you want to know more about our approach to investment management, including how we select the funds on our buy list, please contact your Adviser.</p>
        </div>
        <div class="col-4">
            <Button class="full-width clarity-btn clarity-gradient-orange my-3">Upgrade your Account</Button>
            <Button class="full-width clarity-btn clarity-gradient-grey my-3">Contact your Adviser</Button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IMSUpgrade',
        setup() {
            return {}
        }
    }
</script>

<style scoped>
    button {
        justify-content: center;
    }
</style>