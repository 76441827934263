<template>
    <Modal :width="`858px`">
        <template #body>
            <content-card-full>
                <template #title>
                    <div class="flex justify-content-between title align-items-center">
                        <card-title>
                            <template #title>
                                <div class="flex" style="padding-top: 15px">
                                    <h3 class="blue">Approval Failed</h3>
                                </div>
                            </template>
                        </card-title>
                        <Button class="clarity-close-modal-btn" @click="closeModal">
                            <span class="material-icons-round grey">close</span>
                        </Button>
                    </div>
                </template>
                <template #content-full>
                    <div class="full-width flex flex-column clarity-margin-top-s">
                        <div>
                            No Documents selected for approval. Please ensure you have checked at least one document for approval.
                        </div>
                    </div>
                    <div class="flex justify-content-between btn-group clarity-margin-top-s">
                        <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
                    </div>
                </template>
            </content-card-full>
        </template>
    </Modal>
</template>

<script>
import {useStore} from "vuex";
import Modal from "@/components/common/Modal";
import ContentCardFull from '@/components/common/ContentCardFull';
import CardTitle from '@/components/common/CardTitle';

export default {
    name: 'ImsErrorModal',
    components: {
        Modal,
        ContentCardFull,
        CardTitle,
    },
    setup(){
        const store = useStore();

        const closeModal = () => {
            store.dispatch('setActiveModal');
        }

        return {
            closeModal
        }
    }
}
</script>

<style scoped lang="scss">
.modal:deep(.modal-close) {
  display: none !important;
}

.modal:deep(.content-title) {
  padding: 10px 20px 10px 30px;
}

.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}
.modal:deep(.content-full) {
    padding-top: 0px !important;
}
.modal:deep(.filters) {
    padding: 0px;
}
.selectedMethod {
    color: #0058A9;
    font-weight: bold;
}
.boldtext {
    font-weight: bold;
}
.method-label {
    margin-left: 13px;
}
</style>
