<template>
    <content-card-full>
        <template #title>
            <card-title>
                <template #title>
                    <h2 class="title">IMS FAQs AND INFO</h2>
                </template>
            </card-title>
        </template>
        <template #content-full>
            <div class="grid">
                <div class="col-12">
                    <h3>FAQs</h3>
                    <h5 class="blue">Can I approve some, not all, of the recommended switches?</h5>
                    <p>The switches are grouped together within the individual accounts, so although you may select between products (eg, process the trades within your ISA, but not your pension), the trades within the products that are selected for processing will be transacted in their entirety. Also please do consider that the rebalance proposals are made with the overall portfolio in mind, and so rebalancing some and not all of the products may mean that your portfolio asset allocation does not match the proposed final position.</p>
                    <h5 class="blue">How long are these recommendations valid for?</h5>
                    <p>IMS rebalance recommendations will remain live for approval on this page for up to two months after they are issued. Please do note, however, we do not generally advise returning these instructions more than one month after they are issued as the advice can date, particularly if we have estimated CGT liabilities.</p>
                    <h5 class="blue">Can I discuss the recommendations with my adviser, prior to approval?</h5>
                    <p>Of course. Please contact us on <b>0800 368 7511</b> or via <a><router-link to="/securemessaging/inbox">Secure Message</router-link></a> to arrange a call with your clarity adviser.</p>
                    <h5 class="blue">Do I have to approve the instructions at each rebalance?</h5>
                    <p>Yes, if you want to proceed with the switches recommended. The Investment Management Service is not a discretionary service and we require client confirmation to proceed. The proposed portfolio adjustments cannot be made automatically on your behalf.</p>
                </div>
            </div>

            <div class="grid">
                <div class="col-12 mt-5">
                    <h3>MEET THE TEAM</h3>
                </div>
            </div>

            <div class="grid meet-the-team">
                <div class="col-4 p-4 flex flex-column justify-content-between">
                  <div>
                    <img src="https://ditijyr6nby2p.cloudfront.net/newsales/claritymadeassets/img/team/new-rick.jpg" alt="RICK JONES">
                    <h4 class="blue">RICK JONES</h4>
                    <p>Investment Services Manager</p>
                    <p>Rick joined our administration team in November 2007 having gained 9 years’ experience in the financial sector with Barclays Bank plc. He is now part of the team heading up the IMS rebalancing service.</p>
                  </div>
                    <p class="ims-email-link" @click="$router.push('/securemessaging/message'); newMessageGlobal('Rick Jones');"><span class="material-icons">email</span>Send a Secure Message to Rick</p>
                </div>
                <div class="col-4 p-4 flex flex-column justify-content-between">
                  <div>
                    <img src="https://ditijyr6nby2p.cloudfront.net/newsales/claritymadeassets/img/team/Sara-Hussain.jpg" alt="SARA HUSSAIN">
                    <h4 class="blue">SARA HUSSAIN</h4>
                    <p>Investment Analyst</p>
                    <p>Sara joined clarity as an IMS administrator in January 2021. She graduated from Royal Holloway University of London with a degree in BSc Economics and is looking to study the Investment Management Certificate.</p>
                  </div>
                    <p class="ims-email-link" @click="$router.push('/securemessaging/message'); newMessageGlobal('Sara Hussain');"><span class="material-icons">email</span>Send a Secure Message to Sara</p>
                </div>
                <div class="col-4 p-4 flex flex-column justify-content-between">
                    <div>
                        <img src="https://website-public-assets.clarityglobal.com/saleswebsite/img/franciscaferreira.jpg" alt="FRANCISCA CARVALHO FERREIRA">
                        <h4 class="blue">FRANCISCA CARVALHO FERREIRA</h4>
                        <p>IMS Administrator</p>
                        <p>Kika joined clarity in April 2023 as an Investment Management Services Administrator. Born and bred in Portugal, she graduated with honours in Business Management from Coventry University…</p>
                    </div>
                    <p class="ims-email-link" @click="$router.push('/securemessaging/message'); newMessageGlobal('Francisca Carvalho Ferreira');"><span class="material-icons">email</span>Send a Secure Message to Kika</p>
                </div>
                <!--
                <div class="col-4 p-4 flex flex-column justify-content-between">
                  <div>
                    <img src="https://ditijyr6nby2p.cloudfront.net/newsales/claritymadeassets/img/team/Chris-Finch.jpg" alt="CHRIS FINCH">
                    <h4 class="blue">CHRIS FINCH</h4>
                    <p>IMS Administrator</p>
                    <p>Chris joined clarity in July 2021 as a member of the IMS Team. Prior to joining clarity, Chris studied at the University of Surrey, achieving an MSc in Economics and Finance and a BSc in Economics.</p>
                  </div>
                    <p class="ims-email-link" @click="$router.push('/securemessaging/message'); newMessageGlobal('Chris Finch');"><span class="material-icons">email</span>Send a Secure Message to Chris</p>
                </div>
                -->
            </div>

            <div class="grid">
                <div class="col-12" style="text-align:center;">
                    <a :href="clarityURL+'/aboutteam'" target="_blank"><Button class="clarity-btn clarity-gradient-orange my-3 see-team-btn">See full Team</Button></a>
                </div>
            </div>
        </template>
    </content-card-full>
</template>

<script>
    import CardTitle from '@/components/common/CardTitle';
    import ContentCardFull from '@/components/common/ContentCardFull';
    import {useStore} from "vuex";

    export default {
        name:'IMSFAQs',
        setup() {
          const store = useStore();
          const clarityURL = process.env.VUE_APP_CLARITYURL;


          const newMessageGlobal = (user) => {
            store.dispatch('globalMessageTrigger', {
              subject: 'IMS Message for - '+user,
              category: 'IMS',
              recipient: user,
              messagebody: ''
            })
          }

            return { newMessageGlobal, clarityURL }
        },
        components: {
            CardTitle,
            ContentCardFull,
        }
    }
</script>

<style scoped>
    p {
      font-size: var(--clarity-font-size-18);
    }
    .read-more-btn {
        width: 182px;
        justify-content: center;
        margin: 5px;
    }
    .see-team-btn {
        width: 202px;
        justify-content: center;
        margin: 5px;
    }
    .faq-title {
        font-size: 22px;
        color: var(--clarity-dark-grey);
    }
    .meet-the-team {
        text-align: center;
        padding: 20px;
    }
    .meet-the-team h4 {
        text-align: center;
    }
    .meet-the-team img {
        width: 260px;
        height: 260px;
        margin-bottom: 30px;
        border-radius: 50%;
        box-shadow: 0px 5px 20px #0000000D;
    }
    .material-icons {
        vertical-align: top;
        padding-right: 10px;
    }
    a:-webkit-any-link, a:-webkit-any-link:active {
      color: var(--clarity-light-blue);
    }
    .ims-email-link {
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
</style>
